<template>
  <FormulateForm
    ref="form"
    name="form"
  >
    <div>
      <FormulateInput
        v-if="isEdit"
        v-model="user.userName"
        name="userName"
        type="label"
        :label="$t('Login')"
      />
      <FormulateInput
        v-else
        v-model="user.userName"
        name="userName"
        type="text"
        class="required"
        :label="$t('Login')"
        :instruction="$t('Informe o login que será utilizado para entrar no sistema')"
        :validation="[['required']]"
      />
    </div>
    <div v-if="isEdit">
      <FormulateInput
        v-model="user.active"
        type="switch"
        :label="$t('Ativo?')"
      />
    </div>
    <div>
      <FormulateInput
        v-model="user.name"
        name="name"
        type="text"
        class="required"
        :label="$t('Nome')"
        :placeholder="$t('Nome')"
        validation="required"
      />
    </div>
    <div>
      <FormulateInput
        v-model="user.document"
        mask="###.###.###-##"
        name="document"
        type="text-mask"
        :label="$t('CPF')"
        :placeholder="$t('999.999.999-99')"
        class="required"
        validation="required|cpf"
      />
    </div>
    <div>
      <FormulateInput
        v-model="user.email"
        name="email"
        type="email"
        class="required"
        :label="$t('E-mail')"
        :placeholder="$t('email@dominio.com')"
        validation="required|email"
      />
    </div>
    <div>
      <FormulateInput
        v-model="user.accessProfilesSelected"
        name="accessProfiles"
        type="vue-select"
        class="required"
        :label="$t('Perfis de Acesso')"
        :placeholder="$t('Selecione')"
        :options="accessProfiles"
        :instruction="$t('Selecione um ou mais Perfis de Acesso')"
        validation="required"
        multiple
      />
    </div>
    <div v-if="canAllowAllStores">
      <FormulateInput
        v-model="user.allowAllStores"
        type="switch"
        :label="$t('Habilitar todas as lojas?')"
        :instruction="
          $t('Quando habilitado, o usuário terá acesso a todas as lojas cadastradas no sistema')
        "
      />
    </div>
    <div v-if="!user.allowAllStores">
      <e-store-combo
        id="stores"
        v-model="user.storesSelected"
        type="vue-select"
        class="required"
        :multiple="true"
        :label="$t('Lojas habilitadas')"
        :instruction="$t('Lojas que o usuário terá acesso.')"
      />
    </div>
    <div v-if="isEdit">
      <FormulateInput
        v-model="user.changePassword"
        type="switch"
        :label="$t('Alterar senha?')"
      />
    </div>

    <div v-if="!isEdit || user.changePassword">
      <FormulateInput
        v-model="user.password"
        name="password"
        type="password"
        :label="$t('Senha')"
        :placeholder="$t('Senha')"
        class="required"
        validation="required|min:6,length"
      />
    </div>
    <div v-if="!isEdit || user.changePassword">
      <FormulateInput
        v-model="user.confirmPassword"
        name="confirmPassword"
        type="password"
        :label="$t('Confirmar Senha')"
        :placeholder="$t('Senha')"
        class="required"
        validation="required|confirm:password"
      />
    </div>
  </FormulateForm>
</template>
<script>
import { mapGetters } from 'vuex'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import { isUserAllowedAllStores } from '@/auth/utils'

export default {
  components: { EStoreCombo },
  mixins: [],
  props: {
    isEdit: {
      type: Boolean,
      require: true,
      default: false,
    },
    user: {
      type: Object,
      require: false,
      default: () => ({
        name: '',
        active: true,
        document: '',
        userName: '',
        email: '',
        mainStore: '',
        stores: [],
        storesSelected: [],
        accessProfilesSelected: [],
        password: '',
        confirmPassword: '',
      }),
    },
  },
  data() {
    return {
      profiles: [],
    }
  },
  computed: {
    ...mapGetters('app', ['storeOptions']),
    canAllowAllStores() {
      return isUserAllowedAllStores()
    },
    accessProfiles() {
      return this.$store.state.pages.security.accessProfiles.allAccessProfiles.map(item => ({
        ...item,
        label: item.name,
        value: item.name,
      }))
    },
    // mainStoresOptions() {
    //   return this.storeOptions.filter(
    //     so => this.user.storesSelected && this.user.storesSelected.some(ss => ss === so.value)
    //   )
    // },
  },
  watch: {
    // eslint-disable-next-line func-names
    'user.storesSelected': function (val) {
      if (val) {
        if (this.user.mainStore && !val.some(storeId => this.user.mainStore === storeId)) {
          this.user.mainStore = null
        }
      }
    },
  },
  async mounted() {
    if (!this.$store.state.pages.security.accessProfiles.allAccessProfiles.length) {
      await this.$store.dispatch('pages/security/accessProfiles/fetchallAccessProfiles')
    }
  },
  methods: {
    async validate() {
      this.$refs.form.showErrors()
      return this.$refs.form.isValid
    },
  },
}
</script>
