var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FormulateForm", { ref: "form", attrs: { name: "form" } }, [
    _c(
      "div",
      [
        _vm.isEdit
          ? _c("FormulateInput", {
              attrs: {
                name: "userName",
                type: "label",
                label: _vm.$t("Login"),
              },
              model: {
                value: _vm.user.userName,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "userName", $$v)
                },
                expression: "user.userName",
              },
            })
          : _c("FormulateInput", {
              staticClass: "required",
              attrs: {
                name: "userName",
                type: "text",
                label: _vm.$t("Login"),
                instruction: _vm.$t(
                  "Informe o login que será utilizado para entrar no sistema"
                ),
                validation: [["required"]],
              },
              model: {
                value: _vm.user.userName,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "userName", $$v)
                },
                expression: "user.userName",
              },
            }),
      ],
      1
    ),
    _vm.isEdit
      ? _c(
          "div",
          [
            _c("FormulateInput", {
              attrs: { type: "switch", label: _vm.$t("Ativo?") },
              model: {
                value: _vm.user.active,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "active", $$v)
                },
                expression: "user.active",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      [
        _c("FormulateInput", {
          staticClass: "required",
          attrs: {
            name: "name",
            type: "text",
            label: _vm.$t("Nome"),
            placeholder: _vm.$t("Nome"),
            validation: "required",
          },
          model: {
            value: _vm.user.name,
            callback: function ($$v) {
              _vm.$set(_vm.user, "name", $$v)
            },
            expression: "user.name",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("FormulateInput", {
          staticClass: "required",
          attrs: {
            mask: "###.###.###-##",
            name: "document",
            type: "text-mask",
            label: _vm.$t("CPF"),
            placeholder: _vm.$t("999.999.999-99"),
            validation: "required|cpf",
          },
          model: {
            value: _vm.user.document,
            callback: function ($$v) {
              _vm.$set(_vm.user, "document", $$v)
            },
            expression: "user.document",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("FormulateInput", {
          staticClass: "required",
          attrs: {
            name: "email",
            type: "email",
            label: _vm.$t("E-mail"),
            placeholder: _vm.$t("email@dominio.com"),
            validation: "required|email",
          },
          model: {
            value: _vm.user.email,
            callback: function ($$v) {
              _vm.$set(_vm.user, "email", $$v)
            },
            expression: "user.email",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("FormulateInput", {
          staticClass: "required",
          attrs: {
            name: "accessProfiles",
            type: "vue-select",
            label: _vm.$t("Perfis de Acesso"),
            placeholder: _vm.$t("Selecione"),
            options: _vm.accessProfiles,
            instruction: _vm.$t("Selecione um ou mais Perfis de Acesso"),
            validation: "required",
            multiple: "",
          },
          model: {
            value: _vm.user.accessProfilesSelected,
            callback: function ($$v) {
              _vm.$set(_vm.user, "accessProfilesSelected", $$v)
            },
            expression: "user.accessProfilesSelected",
          },
        }),
      ],
      1
    ),
    _vm.canAllowAllStores
      ? _c(
          "div",
          [
            _c("FormulateInput", {
              attrs: {
                type: "switch",
                label: _vm.$t("Habilitar todas as lojas?"),
                instruction: _vm.$t(
                  "Quando habilitado, o usuário terá acesso a todas as lojas cadastradas no sistema"
                ),
              },
              model: {
                value: _vm.user.allowAllStores,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "allowAllStores", $$v)
                },
                expression: "user.allowAllStores",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    !_vm.user.allowAllStores
      ? _c(
          "div",
          [
            _c("e-store-combo", {
              staticClass: "required",
              attrs: {
                id: "stores",
                type: "vue-select",
                multiple: true,
                label: _vm.$t("Lojas habilitadas"),
                instruction: _vm.$t("Lojas que o usuário terá acesso."),
              },
              model: {
                value: _vm.user.storesSelected,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "storesSelected", $$v)
                },
                expression: "user.storesSelected",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.isEdit
      ? _c(
          "div",
          [
            _c("FormulateInput", {
              attrs: { type: "switch", label: _vm.$t("Alterar senha?") },
              model: {
                value: _vm.user.changePassword,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "changePassword", $$v)
                },
                expression: "user.changePassword",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    !_vm.isEdit || _vm.user.changePassword
      ? _c(
          "div",
          [
            _c("FormulateInput", {
              staticClass: "required",
              attrs: {
                name: "password",
                type: "password",
                label: _vm.$t("Senha"),
                placeholder: _vm.$t("Senha"),
                validation: "required|min:6,length",
              },
              model: {
                value: _vm.user.password,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "password", $$v)
                },
                expression: "user.password",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    !_vm.isEdit || _vm.user.changePassword
      ? _c(
          "div",
          [
            _c("FormulateInput", {
              staticClass: "required",
              attrs: {
                name: "confirmPassword",
                type: "password",
                label: _vm.$t("Confirmar Senha"),
                placeholder: _vm.$t("Senha"),
                validation: "required|confirm:password",
              },
              model: {
                value: _vm.user.confirmPassword,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "confirmPassword", $$v)
                },
                expression: "user.confirmPassword",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }