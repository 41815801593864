var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-store-combo", {
                        attrs: {
                          id: "order-store_id",
                          required: false,
                          label: _vm.$t("Loja"),
                        },
                        model: {
                          value: _vm.filters.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "storeId", $$v)
                          },
                          expression: "filters.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          name: "name",
                          type: "text",
                          label: _vm.$t("Nome do Usuário"),
                          placeholder: _vm.$t("Nome"),
                        },
                        model: {
                          value: _vm.filters.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "name", $$v)
                          },
                          expression: "filters.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          name: "userName",
                          type: "text",
                          label: _vm.$t("Login"),
                          placeholder: _vm.$t("Login"),
                        },
                        model: {
                          value: _vm.filters.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "userName", $$v)
                          },
                          expression: "filters.userName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          name: "email",
                          type: "text",
                          label: _vm.$t("E-mail do Usuário"),
                          placeholder: _vm.$t("E-mail"),
                        },
                        model: {
                          value: _vm.filters.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "email", $$v)
                          },
                          expression: "filters.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          name: "accessProfiles",
                          type: "vue-select",
                          label: _vm.$t("Perfis de Acesso"),
                          placeholder: _vm.$t("Selecione"),
                          options: _vm.accessProfiles,
                        },
                        model: {
                          value: _vm.filters.accessProfile,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "accessProfile", $$v)
                          },
                          expression: "filters.accessProfile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "active",
                          type: "vue-select",
                          label: _vm.$t("Status"),
                          placeholder: _vm.$t("Todos"),
                          options: _vm.activeInactiveTypes(),
                        },
                        model: {
                          value: _vm.filters.active,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "active", $$v)
                          },
                          expression: "filters.active",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "users-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(_vm.$tc("USER.NAME")),
              fields: _vm.fields,
              items: _vm.users,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "show-update": _vm.$can("Update", "User"),
                        "show-delete": _vm.$can("Delete", "User"),
                      },
                      on: {
                        update: function ($event) {
                          return _vm.showUpdateUserSidebar(row)
                        },
                        delete: function ($event) {
                          return _vm.deleteUser(row)
                        },
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(active)",
                fn: function (ref) {
                  var value = ref.value
                  return [_c("e-status-badge", { attrs: { status: value } })]
                },
              },
              {
                key: "cell(stores)",
                fn: function (data) {
                  return [
                    data.item.allowAllStores
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("Todas")))])
                      : _c("label", [
                          _vm._v(
                            _vm._s(
                              data.item.stores
                                .map(function (i) {
                                  return i.id
                                })
                                .join(", ")
                            )
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            { staticClass: "pt-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "user-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("e-sidebar", {
        attrs: {
          title: _vm.edit ? _vm.$t("Editar Usuário") : _vm.$t("Novo Usuário"),
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          saving: _vm.saving,
          width: "500px",
        },
        on: { save: _vm.saveUser, hidden: _vm.onHideSidebar },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("user-form", {
                  ref: "userForm",
                  attrs: { user: _vm.user, "is-edit": _vm.edit },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("b-modal", {
        attrs: {
          id: "history-modal",
          title: _vm.$t("Histórico"),
          "ok-only": "",
          centered: "",
          size: "lg",
          "ok-title": _vm.$t("voltar"),
        },
        model: {
          value: _vm.showHistory,
          callback: function ($$v) {
            _vm.showHistory = $$v
          },
          expression: "showHistory",
        },
      }),
      _vm.$can("Create", "User")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar usuário") },
            on: { click: _vm.showCreateUserSidebar },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }